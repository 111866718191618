import './Card.css';
import Color from '../../css/Colors';
import firebase from '../../firebase';
import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { fetchItemData } from '../../components/services/firebase-controller';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';

const Card = () => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const [cardInfo, setCardInfo] = useState({});
    const [cardId, setCardId] = useState("");
    const [companyInfo, setCompanyInfo] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    let id = searchParams.get("card_key");

    useEffect(() => {
        async function getCard(key) {
            fetchItemData("cards", key).then((val) => {
                setCardInfo(val);
                setCardId(val.uid);
                if (val.plan === 'company') {
                    fetchItemData("companies", val.companyId).then((companyVal) => {
                        setCompanyInfo(companyVal);
                    }).catch((err) => console.log(err));
                } else {
                    console.log("personal card :)");
                }
            }).catch((err) => console.log(err));
        }

        getCard(id);
    }, []);

    const getCompanyImage = () => {
        if (cardInfo.overrideCompanyImage) {
            return cardInfo.overrideCompanyImage;
        }
        return companyInfo?.image || '';
    }

    return (
        <div className="Card">
            <div className="d-flex justify-content-center">
                {cardId !== "" &&
                    <div style={{ alignItems: 'center' }} className="col-12 col-sm-12 col-md-3">
                        <div className="img-overlay-wrap">
                            <img style={{ height: '25%', width: 'auto' }} src={cardInfo?.image} />
                        </div>
                        <div className="content-container-h">
                            <div className="content-container">
                                {cardInfo?.plan === 'company' && getCompanyImage() && (
                                    <img style={{ objectFit: 'contain', height: 45, width: '100%' }} src={getCompanyImage()} />
                                )}
                                <div className="text-container">
                                    <div className="text-names" style={{ margin: 1, color: Color.darkslategray }}>{cardInfo?.firstName} {cardInfo?.lastName}</div>
                                    {cardInfo?.plan === 'company' ? (
                                        <div style={{ fontSize: 12, margin: 2, color: Color.darkslategray }}>
                                            {cardInfo?.occupation} at {cardInfo?.location}
                                        </div>
                                    ) : (
                                        <div style={{ fontSize: 12, margin: 5, color: Color.darkslategray }}>
                                            {cardInfo?.occupation}
                                        </div>
                                    )}
                                </div>
                                {cardInfo?.about !== "" && 
                                    <div className="info-container">
                                        <div style={{ fontSize: 11, opacity: 0.6 }}>ABOUT ME</div>
                                        <div style={{ fontSize: 12 }}>
                                            {cardInfo?.about}
                                        </div>
                                    </div>
                                }
                                <div className="info-container">
                                    {cardInfo?.email !== "" && <a className="card-text" href={`mailto:${cardInfo?.email}`} style={{ color: Color.darkslategray }}><i className="fa-solid fa-envelope"></i>  {cardInfo?.email}</a>}
                                    {cardInfo?.phone !== "" && <a className="card-text" href="" style={{ color: Color.darkslategray }}><i className="fa-solid fa-phone"></i>  {cardInfo?.phone}</a>}
                                    {companyInfo?.companyName !== "" && <a className="card-text" href="" style={{ color: Color.darkslategray }}><i className="fa-solid fa-location-dot"></i>  {companyInfo?.companyName}</a>}
                                    {cardInfo?.site !== "" && <a className="card-text" href={`https://${cardInfo?.site}`} style={{ color: Color.darkslategray }}><i className="fa-solid fa-link"></i>  {cardInfo?.site}</a>}
                                    {cardInfo?.socialHandle !== "" && <a className="card-text" style={{ color: Color.darkslategray }}><i className="fa-solid fa-user-friends"></i>  {cardInfo?.socialHandle}</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {cardId === "" && <div style={{ marginTop: 50 }}><Spinner color={Color.mediumseagreen} size={20} /></div>}
            </div>
        </div>
    );
}

export default Card;
