import React, { useEffect, useState, useCallback, useContext} from 'react';
import { fetchItemData, updateItemData } from '../../components/services/firebase-controller';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Navbar from '../../components/navigation/Navbar';
import Color from '../../css/Colors';
import ServerInputField from '../../components/input/ServerInputField';
import { AuthContext } from '../../components/services/authprovider';

const Profile = () =>{
    const [userInfo, setUserInfo] = useState({});
    const [cardInfo, setCardInfo] = useState({});
    const { currentUser } = useContext(AuthContext);
    const [fetchedData, setFetchedData] = useState(false);

    const [CardId, setCardId] = useState('');

    useEffect(() => {
        const auth = getAuth();

        async function getUser(key) {
            try {
              await fetchItemData("users", key).then((val)=>{        
                setCard(val.card, val.companyId);
                setUserInfo(val);
              });
            } catch (error) {
              console.log(error);
            }
          }

        onAuthStateChanged(auth, (user) => {
            if(user)
            {
              getUser(auth.currentUser?.uid);
            }
          });

       }, []);

       

       const setCard = async (key, companyKey) => {
        await fetchItemData("cards", key).then((val) =>{
            setCardId(val.uid);
            setCardInfo(val);
            setFetchedData(true);
        });
       }

       const saveCard = async (newCardInfo) => {
        try { 
            await updateItemData('cards', CardId, newCardInfo);
        } catch (error) {
            console.error(error);
            throw error;
        }
      };

      const createOnSave = (key, cardInfo, setCardInfo, saveCard) => {
        return (value) => {
          const updatedCardInfo = {
            ...cardInfo,
            [key]: value,
          };
          setCardInfo(updatedCardInfo);
          saveCard(updatedCardInfo);
        };
      };

      const onSaveFirstName = useCallback(createOnSave("firstName", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveLastname = useCallback(createOnSave("lastName", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveEmail = useCallback(createOnSave("email", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSavePhone = useCallback(createOnSave("phone", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveOccupation = useCallback(createOnSave("occupation", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveLocation = useCallback(createOnSave("location", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveSite = useCallback(createOnSave("site", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveSocialHandle = useCallback(createOnSave("socialHandle", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);
      const onSaveAbout = useCallback(createOnSave("about", cardInfo, setCardInfo, saveCard), [cardInfo,setCardInfo,saveCard,]);

    return(
        <div className='Profile'>
            <Navbar/>
            <div className='row d-flex justify-content-center'>
                <div className='col-10 col-sm-10 col-md-10'>
                    <div class="d-flex justify-content-center">
                        { currentUser ? (
                        <div className='d-flex elevated-container m-3 p-2' style={{flexDirection:'column'}}>
                            <ul className="nav nav-tabs" id="profileTab" role="tablist">
                                <li className="nav-item"><button className="nav-link active" id='home-tab' data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</button></li>
                                <li className="nav-item" ><button className="nav-link " id='card-tab' data-bs-toggle="tab" data-bs-target="#card" type="button" role="tab" aria-controls="card" aria-selected="false">Card</button></li>
                            </ul>
                            <div className="tab-content" id="profileTabContent">
                                <div id="home" className="tab-pane fade show active p-3" role="tabpanel" aria-labelledby="home-tab">
                                    <div className='row'>
                                        <p>You can edit the information on your card</p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-6'>
                                        {cardInfo?.plan === 'company' ? (
                                        <div style={{margin:1, color: Color.mediumseagreen}}>
                                            Business Card</div>) 
                                            : (<div style={{margin:1, color: Color.mediumseagreen}}>Personal Card</div> )}
                                        </div>
                                    </div>
                                </div>
                                <div id="card" class="tab-pane fade p-3" role="tabpanel" aria-labelledby="card-tab">
                                    {!fetchedData ? ( <p>Loading...</p>) : (
                                    <div>
                                        <div className='row'>
                                            <p>Edit the your card, image and card activation can be done on the mobile app. </p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                                <ServerInputField
                                                    value={cardInfo['firstName'] || ''}
                                                    onSave={onSaveFirstName}
                                                    label="First Name"
                                                    name="firstName"
                                                    type="text"
                                                    maxLength={20}
                                                    />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                                <ServerInputField
                                                    value={cardInfo['lastName'] || ''}
                                                    onSave={onSaveLastname}
                                                    label="Last Name"
                                                    name="lastName"
                                                    type="text"
                                                    maxLength={20}
                                                    />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                            <ServerInputField
                                                    value={cardInfo['email'] || ''}
                                                    onSave={onSaveEmail}
                                                    label="Email"
                                                    name="email"
                                                    type="text"
                                                    maxLength={30}
                                                    />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                            <ServerInputField
                                                    value={cardInfo['phone'] || ''}
                                                    onSave={onSavePhone}
                                                    label="Phone Number"
                                                    name="phoneNumber"
                                                    type="text"
                                                    maxLength={30}
                                                    />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                            <ServerInputField
                                                    value={cardInfo['occupation'] || ''}
                                                    onSave={onSaveOccupation}
                                                    label="Occupation"
                                                    name="occupation"
                                                    type="text"
                                                    maxLength={45}
                                                    />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                            <ServerInputField
                                                    value={cardInfo['location'] || ''}
                                                    onSave={onSaveLocation}
                                                    label="Location"
                                                    name="location"
                                                    type="text"
                                                    maxLength={25}
                                                    />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                            <ServerInputField
                                                    value={cardInfo['site'] || ''}
                                                    onSave={onSaveSite}
                                                    label="Website"
                                                    name="site"
                                                    type="text"
                                                    maxLength={30}
                                                    />
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-6'>
                                            <ServerInputField
                                                    value={cardInfo['socialHandle'] || ''}
                                                    onSave={onSaveSocialHandle}
                                                    label="Social Handle"
                                                    name="socialHandle"
                                                    type="text"
                                                    maxLength={25}
                                                    />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-12'>
                                            <ServerInputField
                                                    value={cardInfo['about'] || ''}
                                                    onSave={onSaveAbout}
                                                    label="Bio"
                                                    name="bio"
                                                    type="text"
                                                    maxLength={140}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        ) :
                        (
                        <div className='d-flex elevated-container m-3 p-2' >
                            <h3>You are not logged in.</h3>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Profile;